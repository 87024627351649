import React from 'react'

import Styles from "./blogCard.module.css"

function BlogCard({pillColor, data}) {
  return (
    <div className={Styles.card}>
      <div className={Styles.Image}>
        <img src={data.linkToImage} alt="img" />
      </div>
      <div className={Styles.descPill}>
        <span style={pillColor}>{data.tags}</span>
      </div>
      <div className={Styles.title}>{data.title}</div>
      <div dangerouslySetInnerHTML={{__html: data?.description}}></div>
    </div>
  );
}

export default BlogCard