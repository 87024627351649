import React, { useEffect } from "react";

import LogoIcon from "../../assets/icons/LogoIcon.png";
import whatsApp from "../../assets/icons/whatsapp.svg";
import Facebook from "../../assets/icons/facebook.svg";
import LinkedIn from "../../assets/icons/linked.svg";
import Twitter from "../../assets/icons/twitter.svg";
import instagram from "../../assets/icons/instagram.svg";
import ContactIcon from "../../assets/icons/Phone.svg";
import Styles from "./header.module.css";
import menu from "./../../assets/icons/menu.svg";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { getContactSectionData, getLogoUrl } from "../../service";

function Header() {
  const [header, setHeader] = useState(false);
  const [data, setData] = useState({});
  const [logoUrl, setLogoUrl] = useState("")

  useEffect(() => {
    getContactSectionData().then((res) => {
      setData({ ...data, ...res });
      // console.log(res);
    }).catch((err) => {
      console.log(err);
    });
    getLogoUrl().then((downloadURL) => {
      console.log({downloadURL});
      setLogoUrl(downloadURL)
    }).catch(err=>{
      console.log(err);
    })
  }, []);

  return (
    <div className={Styles.container} id='header'>
      {/* <div
        className={Styles.menu}
        onClick={() => {
          setHeader(!header);
        }}
      >
        <img src={menu} alt="" />
      </div> */}
      <div className={`${Styles.content} ${header ? Styles.active : ""}`}>
        <div className={Styles.firstDiv}>
          <div className={Styles.logoContainer} title="Home">
            <NavLink to="/home">
              <div className={Styles.logoIcon}>
                <img src={logoUrl} alt="logo" />
              </div>
            </NavLink>
          </div>
          {/* <div className={Styles.verticalLine}></div> */}
          {/* <div className={Styles.contactContainer}>
            <div className={Styles.contactIcon}>
              <img src={ContactIcon} alt="logo" />
            </div>
            <div className={Styles.contactText}>{data.mobile}</div>
          </div> */}
          {/* <div className={Styles.menu}> */}

            {/* <NavLink
              to="/home"
              className={({ isActive }) =>
                isActive
                  ? `${Styles.menuItem} ${Styles.active}`
                  : `${Styles.menuItem}`
              }
            >
              <div>Home</div>
            </NavLink>
            
            <NavLink
              to="/aboutUs"
              className={({ isActive }) =>
                isActive
                  ? `${Styles.menuItem} ${Styles.active}`
                  : `${Styles.menuItem}`
              }
            >
              <div>About RE IT Solutions</div>
            </NavLink>
            

            <NavLink
              // spy={true}
              // smooth={true}
              to="/home?path=Offerings"
              className={Styles.menuItem}
            >
              <div>Offerings</div>
            </NavLink>

            <NavLink
              // spy={true}
              // smooth={true}
              to="/home?path=getDemo"
              className={Styles.menuItem}
            >
              <div>Get a Demo</div>
            </NavLink>

            <NavLink
              // spy={true}
              // smooth={true}
              to="/home?path=projects"
              className={Styles.menuItem}
            >
              <div>Projects</div>
            </NavLink>

            <NavLink
              to="/contactUs"
              className={({ isActive }) =>
                isActive &&
                (localStorage.getItem("subject") === "Contact us" ||
                  localStorage.getItem("subject") === "Get a Demo")
                  ? `${Styles.menuItem} ${Styles.active}`
                  : `${Styles.menuItem}`
              }
            >
              <div
                onClick={() => {
                  localStorage.setItem("subject", "Contact us");
                }}
              >
                Contact Us
              </div>
            </NavLink> */}
            
            
            {/* <NavLink
              to="/contactUs"
              className={({ isActive }) =>
                isActive &&
                localStorage.getItem("subject") === "Request a Callback"
                  ? `${Styles.menuItem} ${Styles.active}`
                  : `${Styles.menuItem}`
              }
            >
              <div
                onClick={() => {
                  localStorage.setItem("subject", "Request a Callback");
                }}
              >
                Request a Callback
              </div>
            </NavLink> */}

            
          {/* </div> */}
        </div>

        <div className={Styles.secondDiv}>

          <NavLink
            to="/home"
            className={({ isActive }) =>
              isActive
                ? `${Styles.menuItem} ${Styles.active}`
                : `${Styles.menuItem}`
            }
          >
            <div>Home</div>
          </NavLink>
          
          <NavLink
            to="/aboutUs"
            className={({ isActive }) =>
              isActive
                ? `${Styles.menuItem} ${Styles.active}`
                : `${Styles.menuItem}`
            }
          >
            <div>About RE IT Solutions</div>
          </NavLink>
          

          <NavLink
            // spy={true}
            // smooth={true}
            to="/home?path=Offerings"
            className={Styles.menuItem}
          >
            <div>Offerings</div>
          </NavLink>

          <NavLink
            // spy={true}
            // smooth={true}
            to="/home?path=getDemo"
            className={Styles.menuItem}
          >
            <div>Get a Demo</div>
          </NavLink>

          <NavLink
            // spy={true}
            // smooth={true}
            to="/home?path=projects"
            className={Styles.menuItem}
          >
            <div>Projects</div>
          </NavLink>

          <NavLink
            to="/contactUs"
            className={({ isActive }) =>
              isActive &&
              (localStorage.getItem("subject") === "Contact us" ||
                localStorage.getItem("subject") === "Get a Demo")
                ? `${Styles.menuItem} ${Styles.active}`
                : `${Styles.menuItem}`
            }
          >
            <div
              onClick={() => {
                localStorage.setItem("subject", "Contact us");
              }}
            >
              Contact Us
            </div>
          </NavLink>

          <a href={`https://wa.me/${data.whatsapp}`} target="_blank">
            <img
              src={whatsApp}
              alt=""
              style={{ height: "3.5rem", width: "3.5rem" }}
              title="Whatsapp"
            />
          </a>
          {/* <a href={data.facebook} target="_blank">
            <img src={Facebook} alt="" title="Facebook" />
          </a>
          <a
            href={data.linkedIn}
            target="_blank"
            style={{ height: "3rem", width: "2.5rem" }}
          >
            <img src={LinkedIn} alt="" title="LinkedIn" />
          </a>
          <a href={data.twitter} target="_blank">
            <img src={Twitter} alt="" title="Twitter" />
          </a>
          <a href={data.instagram} target="_blank">
            <img src={instagram} alt="" title="Instagram" />
          </a> */}

          {/* <div className={Styles.login}>Login</div>
          <button className={Styles.getStarted}>Get Started</button> */}
        </div>
      </div>
    </div>
  );
}

export default Header;
