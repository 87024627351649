import React, { useEffect, useState } from "react";
import ShowMoreIcon from "../../assets/icons/ShowMoreIcon.svg";
import Styles from "./footerFeature.module.css";
import Card from "../../subComponent/Card/Card";
import {
  getFeatureSectionCards,
  getFeatureSectionStaticData,
} from "../../service";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
function FooterFeature() {
  const [data, setData] = useState({});
  const [cards, setCards] = useState([]);
  useEffect(() => {
    getFeatureSectionStaticData()
      .then((res) => {
        setData({ ...data, ...res });
      })
      .catch((err) => {
      });
    getFeatureSectionCards()
      .then((res) => {
        setCards(res);
      })
      .catch((err) => {
      });
  }, []);
  useEffect(() => {
  }, [cards])
  return (
    <>
      {data?.title && cards && cards.length > 0 && (
        <div className={Styles.container} id="projects">
          <div className={`${Styles.titleContainer} swiperContainer`}>
            <div className={Styles.title}>{data.title}</div>
            <div className={Styles.semiTitleContainer}>
              <div className={Styles.semiTitle}>{data.subTitle}</div>
              {/* <div className={Styles.showMoreButton}>
                <p>Show More</p>
                <img src={ShowMoreIcon} alt="Icon" />
              </div> */}
            </div>
          </div>
          <div className={Styles.swiperContainer}>
            <Swiper
              // slidesPerView={3}
              spaceBetween={30}
              // centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              className="mySwiper"
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@0.75": {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                "@1.00": {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                "@1.50": {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
              modules={[Pagination, Navigation]}
              // navigation={{ nextEl: "#swiper-forward-f", prevEl: "#swiper-back-f" }}
            >
              {cards &&
                cards.map((cardItem, index) => (
                  <SwiperSlide key={index} className={Styles.animationCard}>
                    {/* <Card
                      data={card}
                      styles={{
                        background:
                          "linear-gradient(140.71deg, #F3FBFF 8.19%, #E3F7FF 100%)",
                      }}
                    /> */}
                    {/* <div className={Styles.animationCard} > */}
                      <div className={Styles.animationCardImageWrapper}>
                        <img src={cardItem.linkToImage} alt="icon" />
                      </div>
                      <div className={Styles.animationCardTextWrapper}>
                        <div className={Styles.animationCardTitle}>{cardItem.title}</div>
                        {/* <div className={Styles.subTitle}>{Styles.subTitle}</div> */}
                      </div>
                    {/* </div> */}
                  </SwiperSlide>
                ))}
            </Swiper>
            {/* <div className="left">
              <img
                src={ShowMoreIcon}
                alt=""
                className="swiper-button-prev"
                id="swiper-back-f"
              />
            </div>
            <div className="right">
              <img
                src={ShowMoreIcon}
                alt=""
                className="swiper-button-next"
                id="swiper-forward-f"
              />
            </div> */}
          </div>
          {/* <div className={Styles.cardsContainer}>
            {cards &&
              cards.map((card, index) => (
                <Card
                  data={card}
                  styles={{
                    background: "linear-gradient(140.71deg, #F3FBFF 8.19%, #E3F7FF 100%)",
                    height: 'unset',
                    gap: '3rem',
                    width: window.innerWidth > 768 ? 'calc(33.33% - 3rem)' : '100%',
                    boxShadow: '0px 60px 80px -32px rgba(0, 0, 0, 0.06)',
                    border: 'unset'
                  }}
                />
              ))}
          </div> */}
        </div>
      )}
    </>
  );
}
export default FooterFeature;
