import { useEffect, useState } from "react";
import { getContactSectionData, sendMail } from "../../service";
import {
  showErrorToast,
  showSuccessToast,
  showValidationErrorToast,
} from "../../subComponent/Toast";
import Styles from "./contact.module.css";
import PlaceIcon from '@mui/icons-material/Place';
function Contact() {
  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    msg: "",
  });
  const [validation, setValidation] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const clear = () => {
    setData({
      name: "",
      email: "",
      mobile: "",
      msg: "",
    });
  };
  const [contactData, setContactData] = useState({});
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  useEffect(() => {
    getContactSectionData()
      .then((res) => {
        setContactData({ ...contactData, ...res });
      })
      .catch((err) => {
      });
  }, []);
  const submit = () => {
    if (
      !validation.name.trim() &&
      !validation.email.trim() &&
      !validation.mobile.trim() &&
      data.name.trim() &&
      data.email.trim() &&
      data.mobile.trim()
    ) {
      setIsLoadingButton(true);
      sendMail(contactData.email, data)
        .then((res) => {
          clear();
          showSuccessToast("Mail Sent SuccessFully");
          setIsLoadingButton(false);
        })
        .catch((err) => {
          showErrorToast(err.message);
          setIsLoadingButton(false);
        });
    } else {
      showValidationErrorToast();
    }
  };
  return (
    <div className={Styles.container} id='contactUs'>
      <div className={Styles.form}>
        <div className={Styles.title}> Contact Us </div>
        <div className={Styles.contactWrap}>
          {contactData?.imageUrl !== '' &&
            <div className={Styles.imgWrap}>
              <img src={contactData.imageUrl} alt="Contact Us" />
            </div>
          }
          <div className={Styles.contactUsWrap}>
            <div className={Styles.officesWrap}>
              <div className={Styles.title}>
                Our Locations
              </div>
              <div className={Styles.officeLoctionWrap}>
                <div className={Styles.officeLoction}>
                  <div className={Styles.locationIcon}>
                    <PlaceIcon sx={{ width: '3rem', height: '3rem', color: '#98A2B3' }} />
                  </div>
                  <div className={Styles.locationAddress}>
                    Shivamogga
                    <div>2 nd Stage Vinabonagar, Shivamogga</div>
                    <div>India</div>
                  </div>
                </div>
                <div className={Styles.officeLoction}>
                  <div className={Styles.locationIcon}>
                    <PlaceIcon sx={{ width: '3rem', height: '3rem', color: '#98A2B3' }} />
                  </div>
                  <div className={Styles.locationAddress}>
                    Bengaluru
                    <div>Bengaluru, India 560099</div>
                    <div>India</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.formWrapper}>
              <div className={Styles.title}>
                {/* Get in touch with us! */}
                Let’s Start a Conversation Today!
              </div>
              <div className={Styles.formContainer}>
                <div className={Styles.left}>
                  <div className={Styles.inputItem}>
                    <label htmlFor="name">
                      Name <span className={Styles.star}>*</span>
                    </label>
                    <input
                      className={Styles.input}
                      placeholder="Name"
                      id="name"
                      name="name"
                      value={data.name}
                      onChange={(e) => {
                        handleChange(e);
                        if (!e.target.value.trim()) {
                          setValidation({
                            ...validation,
                            name: "Name cannot be empty!",
                          });
                        } else {
                          setValidation({
                            ...validation,
                            name: "",
                          });
                        }
                      }}
                    />
                    {validation.name && (
                      <div className={Styles.helperText}>{validation.name}</div>
                    )}
                  </div>
                  <div className={Styles.inputItem}>
                    <label htmlFor="mobile">
                      Contact No. <span className={Styles.star}>*</span>
                    </label>
                    <input
                      className={Styles.input}
                      placeholder="Contact No."
                      id="mobile"
                      name="mobile"
                      value={data.mobile}
                      onChange={(e) => {
                        handleChange(e);
                        if (!e.target.value.trim()) {
                          setValidation({
                            ...validation,
                            mobile:
                              "Contact no. cannot be empty!",
                          });
                        } else if (!e.target.value.match(/^[6-9]\d{9}$/gi)) {
                          setValidation({
                            ...validation,
                            mobile: "Enter valid contact no.",
                          });
                        } else {
                          setValidation({
                            ...validation,
                            mobile: "",
                          });
                        }
                      }}
                    />
                    {validation.mobile && (
                      <div className={Styles.helperText}>{validation.mobile}</div>
                    )}
                  </div>
                  <div className={Styles.inputItem}>
                    <label htmlFor="email">
                      E-mail <span className={Styles.star}>*</span>
                    </label>
                    <input
                      className={Styles.input}
                      placeholder="E-mail"
                      id="email"
                      name="email"
                      value={data.email}
                      onChange={(e) => {
                        handleChange(e);
                        if (!e.target.value.trim()) {
                          setValidation({
                            ...validation,
                            email: "Email cannot be empty!",
                          });
                        } else if (
                          !e.target.value.match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          )
                        ) {
                          setValidation({
                            ...validation,
                            email: "Enter a valid email!",
                          });
                        } else {
                          setValidation({
                            ...validation,
                            email: "",
                          });
                        }
                      }}
                    />
                    {validation.email && (
                      <div className={Styles.helperText}>{validation.email}</div>
                    )}
                  </div>
                </div>
                <div className={Styles.right}>
                  <div className={Styles.inputItem}>
                    <label htmlFor="msg">Message</label>
                    <textarea
                      className={Styles.input}
                      id="msg"
                      placeholder="Message"
                      rows={4}
                      name="msg"
                      value={data.msg}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className={Styles.btnContainer}>
                <div
                  className={Styles.btn}
                  style={{ border: "1px solid #D0D5DD" }}
                  onClick={clear}
                >
                  Clear
                </div>
                <div
                  className={Styles.btn}
                  style={{ background: "#7F56D9", color: "#FFFFFF" }}
                  onClick={submit}
                >
                  Submit {isLoadingButton && <div style={{ marginLeft: "1rem" }} class="lds-ring"><div></div><div></div><div></div><div></div></div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
