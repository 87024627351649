import React, { useEffect, useState } from "react";
import Styles from "./FAQ.module.css";
import AccordionItem from "../../subComponent/accordinItem/AccordionItem";
import { getFAQSectionCards, getFAQSectionStaticData } from "../../service";
function FAQ() {
  const [selected, setSelected] = useState(null);
  const [staticData, setStaticData] = useState({});
  const [cards, setCards] = useState([]);
  const toggle = (i) => {
    if (selected === i) {
      setSelected(null);
    } else {
      setSelected(i);
    }
  };
  useEffect(() => {
    getFAQSectionStaticData()
      .then((res) => {
        setStaticData({ ...staticData, ...res });
      })
      .catch((err) => {
      });
    getFAQSectionCards()
      .then((res) => {
        setCards(res);
      })
      .catch((err) => {
      });
  }, []);
  return (
    <div className={Styles.container} id="FAQs">
      <div className={Styles.titleContainer}>
        <div className={Styles.title}>{staticData.title}</div>
        <div className={Styles.subtitle}>{staticData.subTitle}</div>
      </div>
      <div className={Styles.accordion}>
        {cards &&
          cards.map((item, i) => (
            <AccordionItem
              key={i}
              item={item}
              i={i}
              selected={selected}
              toggle={toggle}
            />
          ))}
      </div>
    </div>
  );
}
export default FAQ;
