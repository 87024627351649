import React, { useEffect, useState } from "react";
import ShowMoreIcon from "../../assets/icons/ShowMoreIcon.svg";
import Styles from "./ourServices.module.css";
import Card from "../../subComponent/Card/Card";
import {
  getServiceSectionCards,
  getServiceSectionStaticData,
} from "../../service";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import AOS from 'aos';
import 'aos/dist/aos.css'
function OurServices() {
  const [data, setData] = useState({});
  const [cards, setCards] = useState([]);
  const [modalShow, setModalShow] = useState(null)
  useEffect(() => {
    AOS.init();
    getServiceSectionStaticData()
      .then((res) => {
        setData({ ...data, ...res });
      })
      .catch((err) => {
      });
    getServiceSectionCards().then((res) => {
      setCards(res);
    }).catch((err) => {
    });
  }, []);
  return (
    <>
      {data?.title && data.subTitle && (
        <div className={Styles.container} id="Offerings">
          <div className={Styles.titleContainer}>
            <div className={Styles.title}>{data.title}</div>
            <div className={Styles.semiTitleContainer}>
              <div className={Styles.semiTitle}>{data.subTitle}</div>
              {/* <div className={Styles.showMoreButton}>
            <p>Show More</p>
            <img src={ShowMoreIcon} alt="Icon" />
          </div> */}
            </div>
          </div>
          {/* {cards && cards.length > 0 && (
            <div className="occSwiper">
              <Swiper
                slidesPerView={window.innerWidth > 768 ? 3 : 1}
                spaceBetween={50}
                modules={[Navigation]}
                navigation={{ nextEl: "#swiper-forward-o", prevEl: "#swiper-back-o" }}
                centeredSlides={true}
              >
                {cards &&
                  cards.map((card, index) => (
                    <SwiperSlide key={index}>
                      <Card styles={{ background: "#FFFFFF" }} data={card} />
                    </SwiperSlide>
                  ))}
              </Swiper>
              <div className="left">
                <img
                  src={ShowMoreIcon}
                  alt=""
                  className="swiper-button-prev"
                  id="swiper-back-o"
                />
              </div>
              <div className="right">
                <img
                  src={ShowMoreIcon}
                  alt=""
                  className="swiper-button-next"
                  id="swiper-forward-o"
                />
              </div>
            </div>
          )} */}
          <div className={Styles.animationCardWrapper}>
            {cards && cards.length > 0 && cards.map((cardItem, index) =>(
              <div className={Styles.animationCard} style={{cursor: "pointer"}} key={"Animation-Card-"+index} data-aos="zoom-in-down" data-aos-duration="1000" onMouseEnter={(e) => {setModalShow(cardItem)}}>
                <div className={Styles.animationCardImageWrapper}>
                  <img src={cardItem.linkToImage} alt="icon" />
                </div>
                <div className={Styles.animationCardTextWrapper}>
                  <div className={Styles.animationCardTitle}>{cardItem.title}</div>
                  {/* <div className={Styles.subTitle}>{Styles.subTitle}</div> */}
                </div>
              </div>
            ))}
          </div>
          { modalShow &&
            <div className={Styles.animationCardModal} onMouseLeave={(e) => {}} onMouseOut={(e) => { }} >
              {/*    */}
              <div className={Styles.modalCloseWrapper} onClick={() => {}}></div>
              {/* onClick={() => {}} */}
              <div className={Styles.animationCard} data-aos="zoom-in-down" onMouseLeave={(e) => {}}>
                <div className={Styles.animationCardImageWrapper}>
                  <img src={modalShow.linkToImage} alt="icon" />
                </div>
                <div className={Styles.animationCardTextWrapper}>
                  <div className={Styles.animationCardTitle}>{modalShow.title}</div>
                  <div className={Styles.animationCardSubTitle}>{modalShow.subTitle}</div>
                </div>
              </div>
            </div>
          }
        </div>
      )}
    </>
  );
}
export default OurServices;
