import Styles from "./TermsOfUse.module.css";
import { useEffect, useState } from "react";
import { getTermsOfUse } from "../../service";
function TermsOfUse() {
    const [data, setData] = useState({});
    useEffect(() => {
        getTermsOfUse()
            .then((res) => {
                setData({ ...data, ...res });
                // 
            })
            .catch((err) => {
            });
    }, []);
    return (
        <div className={Styles.container}>
            <div className={Styles.subContainer}>
                <div className={Styles.title}>Terms Of Use</div>
                <div className={Styles.data}>
                    {/* <div className={Styles.left}>
            <img src={data.linkToPic} alt="" />
          </div> */}
                    <div
                        className={Styles.right}
                        dangerouslySetInnerHTML={{ __html: data.desc }}
                    ></div>
                </div>
            </div>
        </div>
    );
}
export default TermsOfUse;
