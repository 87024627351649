import React, { useEffect, useState } from "react";

import Styles from "./ourClient.module.css";
import AirbnbIcon from "./../../assets/icons/AirbnbIcon.svg";
import AristaIcon from "./../../assets/icons/AristaIcon.svg";
import FalconIcon from "./../../assets/icons/FalconIcon.svg";
import JupiterIcon from "./../../assets/icons/JupiterIcon.svg";
import logitechIcon from "./../../assets/icons/logitechIcon.svg";
import { getClientSectionStaticData } from "../../service";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import Card from "../../subComponent/Card/Card";
import ShowMoreIcon from "../../assets/icons/ShowMoreIcon.svg";
import Partners from "../../modals/Partners/Partners";

function OurClient() {
  const [data, setData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    getClientSectionStaticData()
      .then((res) => {
        console.log('res: ', res);
        setData({ ...data, ...res });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {data.cards && data.cards.length > 0 && (
        <div className={Styles.container}>
          {((data.title && data.title !== '') || (data.subTitle && data.subTitle !== '')) && (
            <div className={Styles.titleContainer}>
              {data.title !== '' &&
                <div style={{fontSize: "4rem"}} className={Styles.title}>{data.title}</div>
              }
              {/* {data.subTitle !== '' &&
                <div className={Styles.subtitle}>{data.subTitle}</div>
              } */}
            </div>
          )}
          <div className={Styles.cardsContainer}>
            {/* <div className={Styles.rowOne}>
              <div className={Styles.card}>
                <img src={AirbnbIcon} alt="icon" />
              </div>
            </div> */}
            <div className="" style={{ width: '100%', padding: '0', textAlign: 'center' }}>
              <Swiper
                slidesPerView={5}
                spaceBetween={15}
                // centeredSlides={true}
                loop={true}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}
                modules={[Navigation, Autoplay]}
                navigation={{ nextEl: "#swiper-forward", prevEl: "#swiper-back" }}

                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    // spaceBetween: 10
                  },
                  480: {
                    slidesPerView: 3,
                    // spaceBetween: 15
                  },
                  640: {
                    slidesPerView: 5,
                    // spaceBetween: 15
                  }
                }}
              >
                {data.cards &&
                 data.cards.map((card, index) => (
                    <SwiperSlide key={index} style={{ width: '15rem !important', borderRadius: '2rem !important' }} onClick={() => {
                      setOpenModal(true);
                      setImageUrl(card.linkToImage)
                    }}>
                      <div className={Styles.card}>
                        <img src={card.linkToImage} alt="icon" />
                      </div>
                      {/* <Card
                        data={card}
                        styles={{
                          background: "linear-gradient(140.71deg, #F3FBFF 8.19%, #E3F7FF 100%)",
                        }}
                      /> */}
                    </SwiperSlide>
                  ))}
              </Swiper>
              {/* <div className="left swiper-button-prev"
                id="swiper-back">
                <img src={ShowMoreIcon} alt="Previous" />
              </div>
              <div className="right swiper-button-next"
                id="swiper-forward">
                <img src={ShowMoreIcon} alt="Next" />
              </div> */}
            </div>
          </div>
          {openModal && (
            <Partners open={openModal} handleClose={() => {
              setOpenModal(false);
            }} imageUrl={imageUrl} />
          )}
        </div>
      )}
    </>
  );
}

export default OurClient;
