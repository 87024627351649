import React from "react";

import "./accordionItem.css";
import DownArrow from "./../../assets/icons/DownArrow.svg";

function AccordionItem({ i, item, selected, toggle }) {
  return (
    <div className={selected === i ? "item active" : "item"}>
      <div
        className="title"
        onClick={() => {
          toggle(i);
        }}
      >
        <h1>{item.question}</h1>
        <img src={DownArrow} alt="icon" style={{width: '2rem', height: '2rem'}}/>
      </div>
      <div className={selected === i ? "ans show" : "ans"}>{item.answer}</div>
    </div>
  );
}

export default AccordionItem;
