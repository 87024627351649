import { toast } from "react-toastify";

export const showSuccessToast = (msg) => {
  toast.success(msg);
};

export const showValidationErrorToast = () => {
  toast.warning(
    "Oops Validation Error occurred. Pls check for all input Fields."
  );
};

export const showErrorToast = (err) => {
  toast.error(err);
};
