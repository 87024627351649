/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import AppleStoreIcon from "../../assets/icons/AppleStore.svg";
import GoogleStoreIcon from "../../assets/icons/GooglePlayStore.svg";
import PcStoreIcon from "../../assets/icons/DownloadForPc.svg";
import homeRings from "../../assets/images/homeRings.svg";
import Styles from "./home.module.css";
import { getHomeSectionStaticData } from "../../service";
function Home() {
  const [data, setData] = useState({});
  useEffect(() => {
    getHomeSectionStaticData()
      .then((res) => {
        setData({ ...data, ...res });
      })
      .catch((err) => {
      });
  }, []);
  return (
    <div className={Styles.mainContainer} id="home">
      <img src={data.linkToImage} alt="" className={Styles.backgroundCoverImage} />
      <div className={Styles.container}>
        <div className={Styles.left}>
          <div className={Styles.title}>{data.title}</div>
          <div className={Styles.subtitle}>{data.subTitle}</div>
          <div className={Styles.buttons}>
            <div className={Styles.button}>
              <a target="_blank" href={data.linkToGooglePlayStore}>
                <img src={GoogleStoreIcon} alt="googleStore"  className={Styles.playStoreImages}/>
              </a>
            </div>
            <div className={Styles.button}>
              <a target="_blank" href={data.linkToApplePlayStore}>
                <img src={AppleStoreIcon} alt="appleStore"  className={Styles.appleStoreImages}/>
              </a>
            </div>
            <div className={Styles.button}>
              <a target="_blank" href={data.linkToDownloadForPc}>
                <img src={PcStoreIcon} alt="download for pc"  className={Styles.pcStoreImages}/>
              </a>
            </div>
          </div>
        </div>
        {/* <div className={Styles.right}>
          <img src={data.linkToImage} alt="" className={Styles.largeStroke} />
        </div> */}
      </div>
    </div>
  );
}
export default Home;
