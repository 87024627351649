import React from 'react';
import css from './Partners.module.css';
import CloseIcon from '../../assets/icons/crossIcon.svg';
import Modal from "@mui/material/Modal";

function Partners({ open, handleClose, imageUrl }) {
    function closeModal() {
        handleClose();
    }
    return (
        <Modal
            open={open}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    closeModal();
                }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={[
                {
                    ".MuiBackdrop-root": {
                        background: "rgba(52, 64, 84, 0.7)",
                    },
                },
            ]}
            disableAutoFocus={true}
        // button
        >
            <div className={css.modalContainer}>
                <div className={css.closeIconWrap}>
                    <img src={CloseIcon} alt="Close" onClick={closeModal} tabIndex={0} />
                </div>
                <div className={css.imgWrap}>
                    <img src={imageUrl} alt="Logo" />
                </div>
            </div>
        </Modal>
    );
}

export default Partners;
