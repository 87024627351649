import React from "react";
import { useSwiper } from "swiper/react";

import Styles from "./sliderCard.module.css";

import LogoIcon from "./../../assets/icons/Logo.svg";
import StarIcon from "./../../assets/icons/StarIcon.svg";
import SwiperRightButton from "./../../assets/icons/SwiperRightButton.svg";
import SwiperLeftButton from "./../../assets/icons/SwiperLeftButton.svg";

function SliderCard({ data, cards, index }) {
  const swiper = useSwiper();

  return (
    <div className={Styles.container}>
      <div className={Styles.card}>
        <div className={Styles.Image}>
          <img src={data.linkToPic} alt="img" className={Styles.clientImg}/>
          <img src={StarIcon} alt="img" className={Styles.StarIcon} />
        </div>
        <div className={Styles.cardContent}>
          <div className={Styles.logoContainer}>
            <div className={Styles.logoIcon}>
              <img src={LogoIcon} alt="logo" />
            </div>
            <div className={Styles.logoText}>Ranga</div>
          </div>
          <div className={Styles.desc}>{data.review}</div>
          <div className={Styles.nameContainer}>
            <div className={Styles.name}>{data.name}</div>
            <div className={Styles.profession}>{data.position}</div>
          </div>
          <div className={Styles.controllers}>
            <img
              src={SwiperLeftButton}
              alt="Left"
              id="swiper-back"
              style={{
                pointerEvents: index === 0 ? "none" : "unset",
                opacity: index === 0 ? "0.5" : "unset",
              }}
              className="swiper-button-prev"
              onClick={() => swiper.slidePrev()}
            />
            <img
              src={SwiperRightButton}
              style={{
                pointerEvents: index === cards.length - 1 ? "none" : "unset",
                opacity: index === cards.length - 1 ? "0.5" : "unset",
              }}
              alt="Right"
              className="swiper-button-next"
              id="swiper-forward"
              onClick={() => swiper.slideNext()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderCard;
