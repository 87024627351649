import React, { useEffect, useState } from "react";
import cssClasses from "./Demo.module.css";
import { getContactSectionData, getDemoStaticData, getVideoUrl, sendMail } from "../../service";
import { NavLink } from "react-router-dom";
import Styles from "../../pages/contact/contact.module.css"
import { showErrorToast, showSuccessToast, showValidationErrorToast } from "../../subComponent/Toast";
function Demo() {
  const [demoData, setDemoData] = useState({});
  const [videoUrl, setVideoUrl] = useState("");
  useEffect(() => {
    // getVideoUrl("demo/getDemo.mp4")
    //   .then((res) => {
    //     setVideoUrl(res);
    //   })
    //   .catch((err) => {
    //     
    //   });
      getDemoStaticData().then(res => {
        if (res) {
          setDemoData({...res})
        }
      })
  }, []);
  //* contact
  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    msg: "",
  });
  const [validation, setValidation] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const clear = () => {
    setData({
      name: "",
      email: "",
      mobile: "",
      msg: "",
    });
  };
  const [contactData, setContactData] = useState({});
  useEffect(() => {
    getContactSectionData()
      .then((res) => {
        setContactData({ ...contactData, ...res });
      })
      .catch((err) => {
      });
  }, []);
  const submit = () => {
    if (
      !validation.name.trim() &&
      !validation.email.trim() &&
      !validation.mobile.trim() &&
      data.name.trim() &&
      data.email.trim() &&
      data.mobile.trim()
    ) {
      setIsLoadingButton(true);
      sendMail(contactData.email, data)
        .then((res) => {
          clear();
          showSuccessToast("Mail Sent SuccessFully");
          setIsLoadingButton(false);
        })
        .catch((err) => {
          showErrorToast(err.message);
          setIsLoadingButton(false);
        });
    } else {
      showValidationErrorToast();
    }
  };
  return (
    <>
      <div className={cssClasses.titleContainer} id="getDemo">
        <div className={cssClasses.title}>Get A Demo</div>
        <div className={cssClasses.subtitle}>{""}</div>
      </div>
      <div className={cssClasses.demoWrapper}>
        <div className={cssClasses.addWrapper}>
          <div className={cssClasses.videoParentWrapper}>
            <div className={cssClasses.videoWrapper}>
              <video src={videoUrl} type="video/mp4" controls />
            </div>
          </div>
          <div dangerouslySetInnerHTML={{__html: demoData?.description}} style={{fontSize: "1.4rem"}}></div>
        </div>
        <div className={Styles.container} style={{minHeight: 'unset', padding: '0px', background: 'rgb(255, 255, 255)', boxShadow: 'rgb(0 0 0 / 35%) 0px 5px 15px', padding: '2rem', borderRadius: '8px'}}>
          <div className={Styles.form} style={{width: "100%", gap: "0", padding: "0"}}>
            <div className={Styles.title} style={{lineHeight: "unset"}}> Contact Us </div>
            <div className={Styles.formContainer}  style={{width: "100%", display: "block"}}>
              <div className={Styles.left} style={{width: "100%", display: "block"}}>
                <div className={Styles.inputItem}>
                  <label htmlFor="name">
                    Name <span className={Styles.star}>*</span>
                  </label>
                  <input
                    className={Styles.input}
                    placeholder="Name"
                    id="name"
                    name="name"
                    value={data.name}
                    onChange={(e) => {
                      handleChange(e);
                      if (!e.target.value.trim()) {
                        setValidation({
                          ...validation,
                          name: "Name cannot set be empty. Pls enter value...",
                        });
                      } else {
                        setValidation({
                          ...validation,
                          name: "",
                        });
                      }
                    }}
                  />
                  {validation.name && (
                    <div className={Styles.helperText}>{validation.name}</div>
                  )}
                </div>
                <div className={Styles.inputItem}>
                  <label htmlFor="mobile">
                    Mobile No. <span className={Styles.star}>*</span>
                  </label>
                  <input
                    className={Styles.input}
                    placeholder="Mobile No."
                    id="mobile"
                    name="mobile"
                    value={data.mobile}
                    onChange={(e) => {
                      handleChange(e);
                      if (!e.target.value.trim()) {
                        setValidation({
                          ...validation,
                          mobile:
                            "Mobile No. cannot set be empty. Pls enter value...",
                        });
                      } else if (!e.target.value.match(/^[6-9]\d{9}$/gi)) {
                        setValidation({
                          ...validation,
                          mobile: "Pls Enter valid Contact no.",
                        });
                      } else {
                        setValidation({
                          ...validation,
                          mobile: "",
                        });
                      }
                    }}
                  />
                  {validation.mobile && (
                    <div className={Styles.helperText}>{validation.mobile}</div>
                  )}
                </div>
                <div className={Styles.inputItem}>
                  <label htmlFor="email">
                    E-mail <span className={Styles.star}>*</span>
                  </label>
                  <input
                    className={Styles.input}
                    placeholder="E-mail"
                    id="email"
                    name="email"
                    value={data.email}
                    onChange={(e) => {
                      handleChange(e);
                      if (!e.target.value.trim()) {
                        setValidation({
                          ...validation,
                          email: "Email cannot set be empty. Pls enter value...",
                        });
                      } else if (
                        !e.target.value.match(
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        )
                      ) {
                        setValidation({
                          ...validation,
                          email: "Pls Enter valid email.",
                        });
                      } else {
                        setValidation({
                          ...validation,
                          email: "",
                        });
                      }
                    }}
                  />
                  {validation.email && (
                    <div className={Styles.helperText}>{validation.email}</div>
                  )}
                </div>
                <div className={Styles.inputItem}>
                  <label htmlFor="msg">Message</label>
                  <textarea
                    className={Styles.input}
                    id="msg"
                    placeholder="Message"
                    rows={4}
                    name="msg"
                    value={data.msg}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* <div className={Styles.right}> */}
              {/* </div> */}
            </div>
            <div className={Styles.btnContainer}>
              {/* <div
                className={Styles.btn}
                style={{ border: "1px solid #D0D5DD" }}
                onClick={clear}
              >
                Clear
              </div> */}
              <div
                className={Styles.btn}
                style={{ background: "#001439", color: "#FFFFFF" }}
                onClick={submit}
              >
                Submit {isLoadingButton && <div style={{marginLeft: "1rem"}} class="lds-ring"><div></div><div></div><div></div><div></div></div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{display: "flex", justifyContent: "center", marginTop: "2rem"}}>
        <NavLink to="/contactUs">
          <div
            className={cssClasses.button}
            onClick={() => {
              localStorage.setItem("subject", "Get a Demo");
            }}
          >
            Contact Us
          </div>
        </NavLink>
      </div> */}
    </>
  );
}
export default Demo;
