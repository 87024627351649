import Styles from "./Consulting.module.css";
import { useEffect, useState } from "react";
import { getConsulting } from "../../service";
function Consulting() {
  const [data, setData] = useState({});
  useEffect(() => {
    getConsulting()
      .then((res) => {
        setData({ ...data, ...res });
        // 
      })
      .catch((err) => {
      });
  }, []);
  return (
    <div className={Styles.container}>
      <div className={Styles.subContainer}>
        <div className={Styles.title}>Consulting</div>
        <div className={Styles.data}>
          {/* <div className={Styles.left}>
            <img src={data.linkToPic} alt="" />
          </div> */}
          <div
            className={Styles.right}
            dangerouslySetInnerHTML={{ __html: data.desc }}
          ></div>
        </div>
      </div>
    </div>
  );
}
export default Consulting;
