/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";

import Styles from "./footer.module.css";
import LogoIcon from "../../assets/icons/LogoIcon.png";
// import SendEmailIcon from "./../../assets/icons/SendEmailIcon.svg";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { getContactSectionData, getLogoUrl } from "../../service";
import whatsApp from "../../assets/icons/whatsapp.svg";
import Facebook from "../../assets/icons/facebook.svg";
import LinkedIn from "../../assets/icons/linked.svg";
import Twitter from "../../assets/icons/twitter.svg";
import instagram from "../../assets/icons/instagram.svg";
import ContactIcon from "../../assets/icons/Phone.svg";
import OurClient from "../OurClient/OurClient";
import PlaceIcon from '@mui/icons-material/Place';

function Footer() {
  const [data, setData] = useState({});
  const [logoUrl, setLogoUrl] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    console.log('location', window.location.href);
    getContactSectionData()
      .then((res) => {
        setData({ ...data, ...res });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getLogoUrl().then((downloadURL) => {
      console.log({ downloadURL });
      setLogoUrl(downloadURL)
    }).catch(err => {
      console.log(err);
    })
  }, []);

  return (
    <>
      <OurClient />
      <div className={Styles.container}>
        <div className={Styles.titleContainer}>
          <div className={Styles.title}>
            Ready to get started? Talk to us today
          </div>
          <NavLink to="/contactUs">
            <div
              className={Styles.button}
              onClick={() => {
                localStorage.setItem("subject", "Get a Demo");
              }}
            >
              Contact Us
            </div>
          </NavLink>
        </div>
        <div className={Styles.content}>
          <div className={Styles.left}>
            <div className={Styles.logoContainer}>
              <img src={logoUrl} alt="logo" />
            </div>
            {/* <div className={Styles.logoFooter}>
              <div className={Styles.contactText}>CONTACT</div>
              <div className={Styles.email}>{data.email}</div>
            </div> */}
          </div>
          <div className={Styles.right}>
            <div className={`${Styles.row} ${Styles.officesRow}`}>
              <div className={Styles.rowContent}>
                <NavLink to="/contactUs">
                  <p className={Styles.columnTitle}>Offices</p>
                </NavLink>
              </div>
              <div className={Styles.officeLoctionWrap}>
                <div className={Styles.officeLoction}>
                  <div className={Styles.locationIcon}>
                    <PlaceIcon sx={{ width: '3rem', height: '3rem', color: '#98A2B3' }} />
                  </div>
                  <div className={Styles.locationAddress}>
                    Shivamogga
                    <div>2 nd Stage Vinabonagar, Shivamogga</div>
                    <div>India</div>
                  </div>
                </div>
                <div className={Styles.officeLoction}>
                  <div className={Styles.locationIcon}>
                    <PlaceIcon sx={{ width: '3rem', height: '3rem', color: '#98A2B3' }} />
                  </div>
                  <div className={Styles.locationAddress}>
                    Bengaluru
                    <div>Bengaluru, India 560099</div>
                    <div>India</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.row}>
              <div className={Styles.rowContent}>
                <Link to="Offerings" spy={true} smooth={true}>
                  <p className={Styles.columnTitle}>Offerings</p>
                </Link>
                <NavLink to="/subscriptionsBasedSolutions">
                  <span>Subscription Based Solutions</span>
                </NavLink>
                <NavLink to="/consulting">
                  <span>Consulting</span>
                </NavLink>
              </div>
            </div>
            <div className={Styles.row}>
              <div className={Styles.rowContent}>
                {/* <NavLink to="/contactUs"> */}
                  <p className={Styles.columnTitle}>Quick Links</p>
                {/* </NavLink> */}
                {window.location.href.includes('/home') ?
                  (<Link to="header" spy={true} smooth={true}>
                    <span>Home</span>
                  </Link>) :
                  (<NavLink to="/home">
                    <span>Home</span>
                  </NavLink>)
                }

                <NavLink to="/aboutUs">
                  <span>About&nbsp;Us</span>
                </NavLink>
                <NavLink to="/contactUs">
                  <span>Contact&nbsp;Us</span>
                </NavLink>
                {/* <Link to="blogs" spy={true} smooth={true}>
                  <span>Blogs</span>
                </Link>
                <Link to="FAQs" spy={true} smooth={true}>
                  <span>FAQs</span>
                </Link>*/}
                <Link to="getDemo" spy={true} smooth={true}>
                  <span>Get&nbsp;a&nbsp;Demo</span>
                </Link>
                <Link to="projects" spy={true} smooth={true}>
                  <span>Projects</span>
                </Link>
              </div>
            </div>
          </div>
        </div >
        <div className={Styles.footerRightSection}>
          <div className={Styles.secondDiv}>
            {data?.whatsapp !== '' &&
              <a href={`https://wa.me/${data.whatsapp}`} target="_blank">
                <img src={whatsApp} alt="" style={{ height: "3.5rem", width: "3.5rem" }} title="Whatsapp" />
              </a>
            }
            {data?.facebook !== '' &&
              <a href={data.facebook} target="_blank">
                <img src={Facebook} alt="" title="Facebook" />
              </a>
            }
            {data?.linkedIn !== '' &&
              <a href={data.linkedIn} target="_blank" style={{ height: "3rem", width: "2.5rem" }} >
                <img src={LinkedIn} alt="" title="LinkedIn" />
              </a>
            }
            {data?.twitter &&
              <a href={data.twitter} target="_blank">
                <img src={Twitter} alt="" title="Twitter" />
              </a>
            }
            {data.instagram &&
              <a href={data.instagram} target="_blank">
                <img src={instagram} alt="" title="Instagram" />
              </a>
            }

            {/* <div className={Styles.login}>Login</div>
              <button className={Styles.getStarted}>Get Started</button> */}
          </div>
          <div className={Styles.logoFooter}>
            {/* <div className={Styles.contactText}>CONTACT</div> */}
            <div className={Styles.email}>+91 {data.mobile}</div>
            <div className={Styles.email}>{data.email}</div>
          </div>
        </div>
        <div className={Styles.footer}>
          <span> Copyright © 2022 RE IT Solutions</span>
          <span className={Styles.clickable} onClick={() => { navigate('/privacyPolicy') }}> | Privacy Policy </span>
          <span className={Styles.clickable} onClick={() => { navigate('/termsOfUse') }}> | Terms of Use </span>
        </div>
      </div >
    </>
  );
}

export default Footer;
