import "./App.css";
import LandingPage from "./pages/LandingPage";

import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useRoutes } from "react-router-dom";
import Header from "./components/Header/Header";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer/Footer";
import { useEffect } from "react";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import Consulting from "./pages/Consulting/Consulting";
import SubscriptionsBasedSolutions from "./pages/SubscriptionsBasedSolutions/SubscriptionsBasedSolutions";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const AppWrapper = () => {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    let routes = useRoutes([
      { path: "/", element: <Navigate to={"/home"} /> },
      { path: "/home", element: <LandingPage /> },
      { path: "/contactUs", element: <Contact /> },
      { path: "/aboutUs", element: <About /> },
      { path: "/privacyPolicy", element: <PrivacyPolicy /> },
      { path: "/termsOfUse", element: <TermsOfUse /> },
      { path: "/consulting", element: <Consulting /> },
      { path: "/subscriptionsBasedSolutions", element: <SubscriptionsBasedSolutions /> },
    ]);
    return routes;
  }
  return (
    <Router>
      <Header />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName="toast"
      />
      <AppWrapper />
      <Footer />
    </Router>
  );
}

export default App;
