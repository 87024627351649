import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import Styles from "./testimonial.module.css";
import SliderCard from "../../subComponent/sliderCard/SliderCard";
import LargeStroke from "./../../assets/icons/LargeStroke.svg";
import SmallStroke from "./../../assets/icons/SmallStroke.svg";
import {
  getTestimonialSectionSliderCards,
  getTestimonialSectionStaticData,
} from "../../service";
import Icon from '../../assets/images/Icon.svg';
function Testimonial() {
  const [data, setData] = useState({});
  const [cards, setCards] = useState([]);
  useEffect(() => {
    getTestimonialSectionStaticData()
      .then((res) => {
        setData({ ...data, ...res });
      })
      .catch((err) => {
      });
    getTestimonialSectionSliderCards()
      .then((res) => {
        setCards(res);
      })
      .catch((err) => {
      });
  }, []);
  return (
    <div className={Styles.container} id="clientTestimonial">
      <div className={Styles.content}>
        <div className={Styles.titleWrap}>
          <div className={Styles.titleContainer}>
            <div className={Styles.title}>{data.title}</div>
            <div className={Styles.subtitle}>{data.subTitle}</div>
          </div>
          {window.innerWidth > 768 &&
            <div>
              <img src={Icon} alt="Icon" />
            </div>
          }
        </div>
        <div className={Styles.testSwiper}>
          <Swiper
            modules={[Navigation]}
            navigation={{ nextEl: "#swiper-forward", prevEl: "#swiper-back" }}
          // className="testSwiper"
          >
            {cards &&
              cards.map((card, index) => (
                <SwiperSlide key={index}>
                  <SliderCard data={card} cards={cards} index={index} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <img src={LargeStroke} alt="" className={Styles.largeStroke} />
        <img src={SmallStroke} alt="" className={Styles.smallStroke} />
      </div>
    </div>
  );
}
export default Testimonial;
