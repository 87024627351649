/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import Styles from "./cta_2.module.css";
import AppleStoreIcon from "../../assets/icons/AppleStore.svg";
import GoogleStoreIcon from "../../assets/icons/GooglePlayStore.svg";
import PcStoreIcon from "../../assets/icons/DownloadForPc.svg";
import { getHomeSectionStaticData } from "../../service";
function CTA_2() {
  const [data, setData] = useState({});
  useEffect(() => {
    getHomeSectionStaticData()
      .then((res) => {
        setData({ ...data, ...res });
      })
      .catch((err) => {
      });
  }, []);
  return (
    <div className={Styles.container}>
      <div className={Styles.title}>{data.title}</div>
      <div className={Styles.subtitle}>{data.subTitle}</div>
      <div className={Styles.buttons}>
        <div className={Styles.button}>
          <a target="_blank" href={data.linkToGooglePlayStore}>
            <img src={GoogleStoreIcon} alt="googleStore" />
          </a>
        </div>
        <div className={Styles.button}>
          <a target="_blank" href={data.linkToApplePlayStore}>
            <img src={AppleStoreIcon} alt="appleStore" />
          </a>
        </div>
        <div className={Styles.button}>
          <a target="_blank" href={data.linkToDownloadForPc}>
            <img src={PcStoreIcon} alt="download for pc" />
          </a>
        </div>
      </div>
    </div>
  );
}
export default CTA_2;
