import { app } from "./firebase";
import {
  getDoc,
  getDocs,
  doc,
  collection,
  getFirestore,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
} from "firebase/storage";
import axios from "axios";
const db = getFirestore(app);
export const getHomeSectionStaticData = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/home"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getServiceSectionStaticData = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/services"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getServiceSectionCards = () => {
  return new Promise(function (resolve, reject) {
    getDocs(collection(db, "database/services/cards"))
      .then((response) => {
        let arr = [];
        response.forEach((doc) => {
          arr.push(doc.data());
        });
        resolve(arr);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getPlanFormFirstSection = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/planHighlights/first/data"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getPlanFormSecondSection = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/planHighlights/second/data"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getClientSectionStaticData = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/clients"))
      .then((response) => {
        const respObj = {
          title: '',
          subTitle: '',
          cards: []
        };
        if (response.data()) {
          respObj.title = response.data().title;
          respObj.subTitle = response.data().subTitle;
          getDocs(collection(db, "database/clients/cards")).then((resp) => {
            let arr = [];
            resp.docs.forEach((doc) => {
              arr.push(doc.data());
            });
            respObj.cards = arr;
            resolve(respObj);
          }).catch((err) => {
            reject(err);
          });
        } else {
          resolve(respObj);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getTestimonialSectionStaticData = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/testimonial"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getTestimonialSectionSliderCards = () => {
  return new Promise(function (resolve, reject) {
    getDocs(collection(db, "database/testimonial/cards"))
      .then((response) => {
        let arr = [];
        response.forEach((doc) => {
          arr.push(doc.data());
        });
        resolve(arr);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getBlogSectionStaticData = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/blogs"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getBlogSectionCards = () => {
  return new Promise(function (resolve, reject) {
    getDocs(collection(db, "database/blogs/cards"))
      .then((response) => {
        let arr = [];
        response.forEach((doc) => {
          arr.push(doc.data());
        });
        resolve(arr);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getFAQSectionStaticData = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/FAQ"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getFAQSectionCards = () => {
  return new Promise(function (resolve, reject) {
    getDocs(collection(db, "database/FAQ/cards"))
      .then((response) => {
        let arr = [];
        response.forEach((doc) => {
          arr.push(doc.data());
        });
        resolve(arr);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getFeatureSectionStaticData = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/features"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getFeatureSectionCards = () => {
  return new Promise(function (resolve, reject) {
    getDocs(collection(db, "database/features/cards"))
      .then((response) => {
        let arr = [];
        response.forEach((doc) => {
          arr.push(doc.data());
        });
        resolve(arr);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getContactSectionData = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/contacts"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getAboutSectionData = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/about"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getPrivacyPolicy = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/privacyPolicy"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getTermsOfUse = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/termsOfUse"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getSubscriptionsBasedSolutions = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/subscriptions"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getConsulting = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/consulting"))
      .then((response) => {
        resolve(response.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const sendMail = (contact, data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`https://dmt-node-mailer.herokuapp.com/sendMailTo`, {
        to: [contact],
        subject: localStorage.getItem('subject'),
        message: `<p> Hi! I am ${data.name},</p>
         <p>Email : ${data.email}</p>
         <p>Contact No.: ${data.mobile}</p>
         <p>${data.msg}.</p>
         `,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};
export const getDemoStaticData = () => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "database/Demo")).then((response) => {
      resolve(response.data());
    })
      .catch((err) => {
        reject(err);
      });
  });
};
export async function getLogoUrl() {
  return await getDownloadURL(ref(getStorage(), `logo/websiteLogo.webp`))
}
export async function getVideoUrl(path) {
  return await getDownloadURL(ref(getStorage(), path))
}
