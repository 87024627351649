import React, { useEffect, useState } from "react";
import Styles from "./features.module.css";
import RightVioletIcon from "./../../assets/icons/RightVioletIcon.svg";
import {
  getPlanFormFirstSection,
  getPlanFormSecondSection,
} from "../../service";
function Features() {
  const [firstSectionData, setFirstSectionData] = useState({});
  const [secondSectionData, setSecondSectionData] = useState({});
  useEffect(() => {
    getPlanFormFirstSection().then((res) => {
      setFirstSectionData({ ...firstSectionData, ...res });
    }).catch((err) => {
    });
    // getPlanFormSecondSection().then((res) => {
    //   setSecondSectionData({ ...secondSectionData, ...res });
    // }).catch((err) => {
    //   
    // });
  }, []);
  useEffect(() => {
  }, [firstSectionData, secondSectionData])
  return (
    <>
      {firstSectionData && Object.keys(firstSectionData).length > 0 &&
        <>
          <div className={Styles.backgroundCoverPageContainer} id="planHighlights">
            <img className={Styles.backgroundCoverImage} src={firstSectionData.linkToPic} alt="" />
            <div className={Styles.backgroundCoverContentContainer}>
              <div className={Styles.backgroundCoverContentTextContainer}>
                <div className={Styles.backgroundCoverContentTitle}>{firstSectionData.title}</div>
                <table>
                  <tbody className={Styles.backgroundCoverContentTable}>
                    <tr>
                      <td>
                        <div className={Styles.tickImageWrapper}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                          </svg>
                        </div>
                      </td>
                      <td>{firstSectionData.bulletOne}</td>
                      {/* <td>{">"}</td> */}
                    </tr>
                    <tr>
                      <td>
                        <div className={Styles.tickImageWrapper}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                          </svg>
                        </div>
                      </td>
                      <td>{firstSectionData.bulletTwo}</td>
                      {/* <td>{">"}</td> */}
                    </tr>
                    <tr>
                      <td>
                        <div className={Styles.tickImageWrapper}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                          </svg>
                        </div>
                      </td>
                      <td>{firstSectionData.bulletThree}</td>
                      {/* <td>{">"}</td> */}
                    </tr>
                    <tr>
                      <td>
                        <div className={Styles.tickImageWrapper}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                          </svg>
                        </div>
                      </td>
                      <td>{firstSectionData.bulletFour}</td>
                      {/* <td>{">"}</td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={Styles.backgroundCoverContentPopUpImagesContainer}>
                <img src={firstSectionData.popUpImage1} alt="" />
                <img src={firstSectionData.popUpImage2} alt="" />
              </div>
            </div>
          </div>
          {/* <div className={Styles.container}>
        <div className={Styles.firstFeature}>
          <div className={Styles.top}>
            <div className={Styles.title}>{firstSectionData.title}</div>
            <img src={firstSectionData.linkToPic} alt="img" />
          </div>
          <div className={Styles.bottom}>
            <div className={Styles.subtitle}>{firstSectionData.subTitle}</div>
            <div className={Styles.list}>
              <div className={Styles.listItem}>
                <img src={RightVioletIcon} alt="icon" />
                <p>{firstSectionData.bulletOne}</p>
              </div>
              <div className={Styles.listItem}>
                <img src={RightVioletIcon} alt="icon" />
                <p>{firstSectionData.bulletTwo}</p>
              </div>
              <div className={Styles.listItem}>
                <img src={RightVioletIcon} alt="icon" />
                <p>{firstSectionData.bulletThree}</p>
              </div>
            </div>
          </div>
        </div>
        {secondSectionData && secondSectionData?.title && secondSectionData?.linkToPic && secondSectionData?.linkToPic !== '' && (
          <div className={Styles.secondFeature}>
            <div className={Styles.top}>
              <div className={Styles.title}>{secondSectionData.title}</div>
              <img src={secondSectionData.linkToPic} alt="img" />
            </div>
            <div className={Styles.bottom}>
              <div className={Styles.subtitle}>{secondSectionData.subTitle}</div>
              <div className={Styles.list}>
                <div className={Styles.listItem}>
                  <img src={RightVioletIcon} alt="icon" />
                  <p>{firstSectionData.bulletOne}</p>
                </div>
                <div className={Styles.listItem}>
                  <img src={RightVioletIcon} alt="icon" />
                  <p>{firstSectionData.bulletTwo}</p>
                </div>
                <div className={Styles.listItem}>
                  <img src={RightVioletIcon} alt="icon" />
                  <p>{firstSectionData.bulletThree}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div> */}
        </>
      }
    </>
  );
}
export default Features;
