import React, { useEffect } from "react";

// import Styles from "./landingPage.module.css"
import Header from "../components/Header/Header";
import Home from "../components/Home/Home";
import OurClient from "../components/OurClient/OurClient";
import OurServices from "../components/OurServices/OurServices";
import Testimonial from "../components/Testimonial/Testimonial";
import Blogs from "../components/Blogs/Blogs";
import FAQ from "../components/FAQ/FAQ";
import Features from "../components/Features/Features";
// import CTA_1 from "../components/CTA_01/CTA_1";
import CTA_2 from "../components/CTA_02/CTA_2";
import FooterFeature from "../components/FooterFeature/FooterFeature";
import Footer from "../components/Footer/Footer";
import { useSearchParams } from 'react-router-dom';
import Demo from "../components/Demo/Demo";

function LandingPage() {

  const [searchParams] = useSearchParams();
  const path = searchParams.get('path')
  
  useEffect(() => {
    if (path && document.getElementById(path)) {
      document.getElementById(path).scrollIntoView();
      // window.location.href = `#${path}`;
    }
  }, [path])
  


  return (
    <>
      <Home />
      <OurServices />
      <Features />
      {/* <CTA_1 /> */}
      <Testimonial />
      <Blogs />
      <FAQ />
      <Demo />
      <CTA_2 />
      <FooterFeature />
      {/* <OurClient /> */}
      {/* <Footer /> */}
    </>
  );
}

export default LandingPage;
