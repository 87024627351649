import React, { useEffect, useState } from "react";
import Styles from "./blogs.module.css";
import BlogCard from "../../subComponent/blogCard/BlogCard";
import { getBlogSectionCards, getBlogSectionStaticData } from "../../service";
function Blogs() {
  const [data, setData] = useState({});
  const [cards, setCards] = useState([]);
  useEffect(() => {
    getBlogSectionStaticData()
      .then((res) => {
        setData({ ...data, ...res });
      })
      .catch((err) => {
      });
    getBlogSectionCards()
      .then((res) => {
        setCards(res);
      })
      .catch((err) => {
      });
  }, []);
  return (
    <>
      {data?.title && cards && cards.length > 0 && (
        <div className={Styles.container} id="blogs">
          <div className={Styles.titleContainer}>
            <div className={Styles.title}>{data.title}</div>
            <div className={Styles.subtitle}>{data.subTitle}</div>
          </div>
          <div className={Styles.cardsContainer}>
            {cards &&
              cards.map((card, index) => (
                <BlogCard
                  key={index}
                  pillColor={{ color: "#8960EE" }}
                  data={card}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
}
export default Blogs;
